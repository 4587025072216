import getToken from './getToken';

// GET JSON
export const getJSON = function(url, callback, onError = undefined, timeout = 0) {
  var request = new XMLHttpRequest();

  request.timeout = timeout;
  request.ontimeout = () =>  {
    console.log('Error: AJAX Request Timed Out');
    onError(request);
  }

  request.open('GET', url, true);

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      onError(request)
    }
  };

  request.send();
}


// POST FORM DATA
export const postData = function(data, url, callback){
  var request = new XMLHttpRequest();

  request.open('POST', url, true);

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      // console.log('status is ALL GOOD !!! ');
      var data = JSON.parse(request.responseText);
      callback(data);
    } else {
      console.log('Error: AJAX Request Failed');
    }
  };

  request.send(data);
}


// DELETE
export const deleteData = function(url, callback, onError = undefined) {
  var request = new XMLHttpRequest();

  const token = getToken();

  request.open('DELETE', url, true);
  request.setRequestHeader('X-CSRF-Token', token)

  request.onload = function() {
    if (request.status >= 200 && request.status < 400) {
      callback();
    } else {
      console.log('Error: AJAX Request Failed');
      onError(request)
    }
  };

  request.send();
}
