import Rails from '@rails/ujs';

const resetFormAndSubmit = () => {
  const inputs = document.querySelectorAll('.reset-form-on-click');

  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener('click', (e) => {
      e.preventDefault();
      resetForm(inputs[i]);
    });
  }
}

const resetForm = (el) => {
  const forms = document.querySelectorAll('form[data-remote]');
  for (let i = 0; i < forms.length; i++) {
    if (forms[i].contains(el) || forms[i].parentElement.contains(el)) {
      forms[i].reset();
      Rails.fire(forms[i], 'submit');
      hideResetButton();
      break;
    }
  }
}

const hideResetButton = () => {
  const resetBtn = document.querySelector('.reset-button-wrapper');
  if (resetBtn) resetBtn.classList.toggle('trans');
}

document.addEventListener('turbolinks:load', resetFormAndSubmit)
