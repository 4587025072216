var showMembershipRequest = function(event) {
  if (event.target.classList.contains('.accept-membership-request')) {
    show('.accept-membership-request-fields');
    hide('.reject-membership-request-fields');
  }
}

var hideMembershipRequest = function(event) {
  if (event.target.classList.contains('.reject-membership-request')) {
    hide('.accept-membership-request-fields');
    show('.reject-membership-request-fields');
  }
}

document.addEventListener('click', showMembershipRequest);
document.addEventListener('click', hideMembershipRequest);
