import * as vis from './visibility';

const scrollLeftRight = () => {
  const scrollContainers = document.querySelectorAll('.scroll-left-right-container');

  for(let i = 0; i < scrollContainers.length; i++){
    const scrollContainer = scrollContainers[i];

    // Find Left and Right scroll indicators
    let scroller;
    let left;
    let right;
    for(let i = 0; i < scrollContainer.children.length; i++){
      if(scrollContainer.children[i].classList.contains('scroller')){
        scroller = scrollContainer.children[i];
      } else if(scrollContainer.children[i].classList.contains('scroll-left')){
        left = scrollContainer.children[i];
      } else if(scrollContainer.children[i].classList.contains('scroll-right')){
        right = scrollContainer.children[i];
      }
    }

    if(scroller && left && right){

      // VISIBILITY

      toggleScrollArrows(scroller, left, right)

      // reset visibility when scrolling...
      scroller.addEventListener(
        'scroll',
        () => toggleScrollArrows(scroller, left, right)
      )

      // ... and when resizing the window
      window.addEventListener(
        'resize',
        () => toggleScrollArrows(scroller, left, right)
      )

      // HOVER LISTENERS

      let timer;

      const animateScrollLeft = () => {
        timer = setInterval(
          () => scroller.scrollLeft -= 5,
          20
        )
      };

      const animateScrollRight = () => {
        timer = setInterval(
          () => scroller.scrollLeft += 5,
          20
        )
      };

      const clearTimer = () => {
        clearInterval(timer);
      }

      const enableHoverListeners = () => {
        left.firstElementChild.addEventListener('mouseenter', animateScrollLeft)
        left.firstElementChild.addEventListener('mouseout', clearTimer)

        right.firstElementChild.addEventListener('mouseover', animateScrollRight)
        right.firstElementChild.addEventListener('mouseout', clearTimer)
      }

      const disableHoverListeners = () => {
        left.firstElementChild.removeEventListener('mouseenter', animateScrollLeft)
        left.firstElementChild.removeEventListener('mouseout', clearTimer)

        right.firstElementChild.removeEventListener('mouseover', animateScrollRight)
        right.firstElementChild.removeEventListener('mouseout', clearTimer)
      }

      // initialize with hover listeners
      enableHoverListeners();

      // CLICK LISTENERS
      // When clicked, animate to other end,
      // disabling hover listeners for the duration

      left.firstElementChild.addEventListener('click', () => {
        disableHoverListeners();
        clearInterval(timer);
        scroller.scrollTo({
          top: scroller.scrollTop,
          left: 0,
          behavior: 'smooth'
        });
        setTimeout(
          enableHoverListeners(),
          500
        )
      })

      right.firstElementChild.addEventListener('click', () => {
        disableHoverListeners();
        clearInterval(timer);
        scroller.scrollTo({
          top: scroller.scrollTop,
          left: scroller.scrollWidth - scroller.clientWidth,
          behavior: 'smooth'
        });
        setTimeout(
          enableHoverListeners(),
          500
        )
      })
    }
  }
}


const toggleScrollArrows = (element, left, right) => {
  if(element.scrollWidth > element.clientWidth){
    const buffer = 2;
    const fullyLeft = element.scrollLeft === 0;
    const fullyRight = element.scrollLeft >= ((element.scrollWidth - element.clientWidth) - buffer);

    if(fullyLeft) vis.fadeOut(left);
    else vis.fadeIn(left);

    if(fullyRight) vis.fadeOut(right);
    else vis.fadeIn(right);
    
  } else {
    // Hide both if the container if is bigger than scrollable area
    vis.fadeOut(left);
    vis.fadeOut(right);
  }
}




document.addEventListener('turbolinks:load', scrollLeftRight);
