// core version + navigation, pagination modules:
import Swiper, { Pagination } from "swiper";

document.addEventListener("turbolinks:load", function () {
  const swiper = new Swiper(".swiper", {
    modules: [Pagination],
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoHeight: true,
  });
});
