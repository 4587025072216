// Reset button on Course Finder is hidden using .trans class on load.
// When a checkbox in the Course Finder is checked it should be shown.

document.addEventListener('turbolinks:load', () => {
  const courseFinder = document.getElementById('course-finder');
  if(!courseFinder) return;

  const resetBtn = courseFinder.querySelector('.reset-button-wrapper');

  const form = courseFinder.querySelector('form');
  form.addEventListener('submit', (e) => { 
    resetBtn.classList.remove('trans'); 
  });
});