// We are experiencing a bug with Course Review. Attachments in the ActionText 
// associations seem to disappear after the CourseReview has been updated.
// This causes 500 errors and crashes throughout the app. Temporarily, we are
// hiding the attachment button from the CourseReview trix editor.

const hideAttachmentButtonFromCourseReviewTrixEditors = () => {
  const body = document.querySelector('body.course_reviews');
  if (body) {
    const buttonGrp = document.querySelectorAll(
      '.trix-button-group--file-tools'
    );

    for(let i = 0; i < buttonGrp.length; i++) {
      buttonGrp[i].style.display = 'none';
    }
  }
}

document.addEventListener(
  'turbolinks:load', 
  hideAttachmentButtonFromCourseReviewTrixEditors
);