const clickToPlayVideo = () => {
  const elements = document.querySelectorAll(".click-to-play-video");
  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener("click", () => {
      const src = elements[i].getAttribute("data-src");
      if (!src) {
        console.log("video not found");
        return;
      }

      const video = document.createElement("video");
      video.controls = true;
      video.download = false;
      video.src = src;
      video.style.pointerEvents = "all";
      document.querySelector("body").appendChild(video);
      video.play();

      const deleteOnUnFullscreen = () => {
        document.addEventListener("fullscreenchange", (e) => {
          if (document.fullscreenElement === null) {
            video.pause();
            video.remove();
          }
        });
      };

      if (video.requestFullscreen) {
        video.requestFullscreen().then(deleteOnUnFullscreen);
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen().then(deleteOnUnFullscreen);
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen().then(deleteOnUnFullscreen);
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen().then(deleteOnUnFullscreen);
      }
    });
  }
};

document.addEventListener("turbolinks:load", clickToPlayVideo);
