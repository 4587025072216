document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('.random-translate');
  for(let i = 0; i < elements.length; i++){
    const element = elements[i];
    const x = randomTranslate();
    const y = randomTranslate();
    element.style.transform = `translate(${x}%, ${y}%) scale(2)`;
  }
})

const randomTranslate = () => {
  return Math.floor(Math.random() * 100) - 50;
}
