document.addEventListener("turbolinks:load", () => {
  const videos = document.querySelectorAll(".my-video");
  for (let i = 0; i < videos.length; i++) {
    videos[i].addEventListener("click", playVideoAndRemoveIcon);
  }
});

const playVideoAndRemoveIcon = (e) => {
  e.target.controls = true;
  e.target.parentElement.querySelector("i").classList.add("trans");
  e.target.removeEventListener("click", playVideoAndRemoveIcon);
};
