import * as vis from './visibility';

const fadeInOnLoad = () => {
  const element = document.getElementById('fade-in-on-load');

  if(element){
    setTimeout(
      () => vis.fadeIn(element),
      2000
    )
  }
}

document.addEventListener('turbolinks:load', fadeInOnLoad)
