export const primary       =  '#00AA81'     ;
export const secondary     =  '#F8ECE2'     ;
export const success       =  '#4D65E1'     ;
export const alert         =  '#FCD95E'     ;
export const warning       =  '#FF8A1F'     ;


export const red           =  '#FF8A1F'     ;
export const pink           =  '#FFC4B7'     ;
export const green         =  '#00AA81'     ;
export const blue          =  '#4D65E1'     ;
export const lightBlue     =  '#A6CAFA'     ;
export const yellow        =  '#FCD95E'     ;
export const mustard        =  '#BCA219'     ;
export const beige         =  '#F8ECE2'     ;
export const gray          =  '#DFD5CF'     ;
export const black         =  '#241E1F'  ;
export const magnolia      =  '#FEF3D6'     ;
export const paleGreen    =  '#E1EBDB'     ;

export const lighterGray  =  '#f5f2ef'     ;
export const lightGray    =  '#ebe4e0'     ;
export const darkGray     =  '#ad9485'     ;
export const darkerGray   =  '#5c493d'     ;

export const cardColors = [
  yellow,
  gray,
  red,
  blue,
  green,
  blue,
  paleGreen,
  red
];

export const colorRef = {
  yellow: yellow,
  gray: gray,
  red: red,
  blue: blue,
  green: green,
  blue: blue,
  paleGreen: paleGreen,
  red: red
};
