import * as vis from './visibility'

const showInfographicPopup = () => {
    const triggers = document.querySelectorAll('.infographic-popup-trigger');

    for (let i = 0; i < triggers.length; i++) {
        const trigger = triggers[i];
        const target = trigger.id.split('-')[trigger.id.split('-').length - 1]

        const mobileTrigger = document.querySelector(`#trigger-mobile-${target}`);
        const popup = document.getElementById('info-popup-' + target);

        const handleClick = (e) => {
          // Fade out all the other popups. Use data-fade-out-class
          // Use data-fade-out-class or default to 'info-popup-container'
          let fadeOutClass = 'info-popup-container';
          if(popup.getAttribute('data-fade-out-class')){
              fadeOutClass = popup.getAttribute('data-fade-out-class');
          }
          vis.fadeOutAllByClass(fadeOutClass);
          if (popup) vis.fadeIn(popup);
          popup.firstChild.nextSibling.addEventListener('click', () => {
              vis.fadeOut(popup);
          })
        }

        if (trigger) trigger.addEventListener('click', handleClick);
        if (mobileTrigger) mobileTrigger.addEventListener('click', handleClick);
    }
}

document.addEventListener('turbolinks:load', showInfographicPopup)
