import * as ajax from "./ajax";
import * as vis from "./visibility";

export var initNotifications = function () {
  var notificationsTrigger = document.getElementById(
    "js-notifications-trigger"
  );

  // check if logged in
  if (notificationsTrigger) {
    setupTriggerListeners();
    ajax.getJSON(
      "/notifications/latest_unread.json",
      buildNotificationsList,
      handleError,
      3000
    );
  }
};

const errorMessage = () => {
  const p = document.createElement("p");
  p.id = "js-notifications-count";
  p.classList.add("strong", "smb");
  p.innerHTML =
    "Oops! We couldn't get your notifications. Refresh the page or try again later.";
  return p;
};

const handleError = function (res) {
  console.log("Error: AJAX Request Failed");
  console.log(res);
  var spinner = document.querySelector("#notifications-spinner");
  if (spinner) spinner.parentNode.replaceChild(errorMessage(), spinner);
};

var setupTriggerListeners = function () {
  var notificationsTrigger = document.getElementById(
    "js-notifications-trigger"
  );
  var notificationsDropdown = document.getElementById(
    "js-notifications-payload"
  );
  var closeNotifications = document.getElementById(
    "close-notifications-payload"
  );

  // NOTIFICATIONS

  // open when clicked
  notificationsTrigger.addEventListener("click", function (event) {
    event.preventDefault();
    this.classList.add("is-open");
    notificationsDropdown.classList.add("is-open");
  });

  // close when clicked outside of dropdown or close button
  document.addEventListener("click", function (event) {
    var isTrigger = event.target === notificationsTrigger;
    var insideTrigger = notificationsTrigger.contains(event.target);
    var insideDropdown = notificationsDropdown.contains(event.target);
    var isClose = event.target === closeNotifications;
    var insideClose = closeNotifications.contains(event.target);
    if (
      (!isTrigger && !insideTrigger && !insideDropdown) ||
      isClose ||
      insideClose
    ) {
      notificationsTrigger.classList.remove("is-open");
      notificationsDropdown.classList.remove("is-open");
    }
  });

  var userTrigger = document.getElementById("js-user-trigger");
  var userDropdown = document.getElementById("js-user-payload");
  var closeUser = document.getElementById("close-user-payload");

  // open when clicked
  userTrigger.addEventListener("click", function (event) {
    event.preventDefault();
    this.classList.add("is-open");
    userDropdown.classList.add("is-open");
  });

  // close when clicked outside of dropdown or close button
  document.addEventListener("click", function (event) {
    var isTrigger = event.target === userTrigger;
    var insideTrigger = userTrigger.contains(event.target);
    var insideDropdown = userDropdown.contains(event.target);
    var isClose = event.target === closeUser;
    var insideClose = closeUser.contains(event.target);
    if (
      (!isTrigger && !insideTrigger && !insideDropdown) ||
      isClose ||
      insideClose
    ) {
      userTrigger.classList.remove("is-open");
      userDropdown.classList.remove("is-open");
    }
  });
};

var buildNotificationsList = function (data) {
  var { notifications_count: count, notifications: nots } = data;

  // Handle Error
  if (count === null || count === undefined) count = 0;
  if (nots === null || nots === undefined) nots = [];

  addCountToList(count);
  addNotificationsToList(nots);
  toggleTrashButton(count);
  addCountToTrigger(count);
  clearCounter(count);
};

var clearCounter = function (count) {
  var containers = document.querySelectorAll(
    ".js-notifications-counter-container"
  );
  var button = document.querySelector("#js-notifications-trash-button");
  button.addEventListener("click", function (event) {
    event.preventDefault();
    for (let i = 0; i < containers.length; i++) {
      vis.removeAllById("js-notifications-counter"); // wipe original
    }
  });
};

var addCountToTrigger = function (count) {
  vis.removeAllById("js-notifications-counter"); // wipe original
  if (count > 0) {
    var containers = document.querySelectorAll(
      ".js-notifications-counter-container"
    );
    for (let i = 0; i < containers.length; i++) {
      var text = document.createTextNode(count);
      var span = document.createElement("span");

      span.id = "js-notifications-counter";

      span.appendChild(text);
      containers[i].appendChild(span);
    }
  }
};

// Toggle Visibility based on whether there are notifications or not
var toggleTrashButton = function (count) {
  var button = document.querySelector("#js-notifications-trash-button");
  if (count >= 1) {
    button.classList.remove("hidden");
  } else {
    button.classList.add("hidden");
  }
};

var addCountToList = function (count) {
  vis.removeById("js-notifications-count"); // wipe existing

  var formattedCount = formatCount(count);
  var text = document.createTextNode(formattedCount);
  var p = document.createElement("p");
  p.id = "js-notifications-count";
  p.classList.add("strong", "smb");
  p.appendChild(text);

  var spinner = document.querySelector("#notifications-spinner");
  if (spinner) spinner.parentNode.replaceChild(p, spinner);
};

var formatCount = function (count) {
  if (count === 0) return "You have no new notifications";
  return "You have " + count + " new notification" + countSuffix(count);
};

var countSuffix = function (count) {
  return count == 1 ? "" : "s";
};

var addNotificationsToList = function (notifications) {
  var list = document.querySelector("#js-notifications-list-here");
  list.innerHTML = ""; // wipe existing
  for (var i = 0; i < notifications.length; i++) {
    addListItems(notifications[i], list);
  }
};

var addListItems = function (data, list) {
  var text = document.createTextNode(data.action);
  var link = document.createElement("a");
  link.href = data.url;
  link.classList.add("nav-bar-notification-item", "ellipsis");
  link.appendChild(text);
  list.appendChild(link);
};

document.addEventListener("turbolinks:load", initNotifications);
