import React from "react";
import PropTypes from "prop-types";

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controls: false,
      showVideo: !props.autoPlay, // Initially hide video if autoPlay is true
      videoPlaying: props.autoPlay, // Assume video is playing if autoPlay is true
      videoLoaded: false,
    };
    this.video = React.createRef();
    this.image = React.createRef();
    this.play = React.createRef();
    this.handleVideoLoad = this.handleVideoLoad.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handleVideoPlay = this.handleVideoPlay.bind(this);
  }

  handleVideoLoad() {
    this.setState({ videoLoaded: true, controls: true });
  }

  handlePlay() {
    this.setState({ controls: true, showVideo: true, videoPlaying: true });
    this.video.current.play();
  }

  handleVideoPlay() {
    // Called when video starts playing
    this.setState({ videoPlaying: true, showVideo: true });
  }

  componentDidMount() {
    // If autoPlay is enabled, adjust the component to reflect that the video is playing
    if (this.props.autoPlay && this.video.current) {
      this.video.current.play();
    }
  }

  render() {
    const { showVideo, videoPlaying } = this.state;
    return (
      <div className={`relative fade-in full-width overflow-hidden`} id="video-container">
        <video
          controlsList="nodownload"
          crossOrigin="anonymous"
          ref={this.video}
          className={`toggle-trans ${showVideo ? "" : "trans"}`}
          style={{ width: "100%" }}
          src={this.props.src}
          alt={this.props.alt}
          controls={this.state.controls}
          onLoadedData={this.handleVideoLoad}
          onPlay={this.handleVideoPlay}
          autoPlay={this.props.autoPlay}
          muted={this.props.muted}
        >
          <track kind="subtitles" src={this.props.subtiles} srcLang="en" label="English" default />
        </video>
        {!videoPlaying && ( // Conditionally render the thumbnail if the video is not playing
          <img
            ref={this.image}
            src={this.props.poster}
            alt="poster"
            className="centred-absolutely full-width full-height cover toggle-trans"
          />
        )}
        {!videoPlaying && ( // Conditionally render the play button if the video is not playing
          <div
            className="absolute hand-on-hover toggle-trans lp circle-radius row align-center-middle blur-bg fade-in"
            ref={this.play}
            id="video-play-button"
            onClick={this.handlePlay}
          >
            <i className="fa fa-play lml dark-gray fade-in"></i>
          </div>
        )}
      </div>
    );
  }
}

// Define propTypes
VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  poster: PropTypes.string,
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
};

// Set default props
VideoPlayer.defaultProps = {
  alt: '',
  poster: '',
  autoPlay: false,
  muted: false,
};

export default VideoPlayer;
