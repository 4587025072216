import * as vis from './visibility'

var showPaginationSpinner = function(event) {
  if (paginationClick(event.target)) showSpinner();
}

var showSearchSpinner = function(event) {
  if (searchClick(event.target)) showSpinner();
}

var paginationClick = function(elem) {
  if (event.target.tagName.toLowerCase() === 'a') {
    return elem.parentNode.parentNode.classList.contains('pagination');
  } else {
    return false;
  }
}

var searchClick = function(elem) {
  return elem.classList.contains('search-button');
}

var showSpinner = function() {
  vis.showById('pagination-spinner');
  vis.hideById('paginated');
}

document.addEventListener('click', showPaginationSpinner);
document.addEventListener('click', showSearchSpinner);
