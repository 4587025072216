import * as vis from './visibility';

const sidebarComments = () => {
  const sidebar = document.getElementById('sidebar');
  const commentsList = document.querySelector('#comments-list');
  if(sidebar && commentsList && sidebar.contains(commentsList)){
    scrollToBottom(commentsList);
    vis.showById('scroll-fade-top')
    // vis.hideById('scroll-fade-bottom');

    toggleScrollFadeOnScrollEnd(commentsList)
  }
}

const scrollToBottom = (el) => {
  el.scrollTop = el.scrollHeight
}

const toggleScrollFadeOnScrollEnd = (el) => {
  el.addEventListener('scroll', (e) => {
    if(el.scrollTop === 0) {
      vis.hideById('scroll-fade-top')
    } else {
      vis.showById('scroll-fade-top');
    };
  })
}

document.addEventListener('turbolinks:load', sidebarComments)
