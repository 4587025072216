import anchorme from "anchorme";

const linkify = () => {
  const comments = document.querySelectorAll(".linkify");

  if (comments) {
    comments.forEach((comment) => {
      comment.innerHTML = anchorme({
        input: comment.innerText,
        options: {
          attributes: {
            target: "_blank",
            rel: "nofollow",
          },
        },
      });
    });
  }
};

document.addEventListener("turbolinks:load", linkify);
document.addEventListener("ajax:success", linkify);

export default linkify;
