import * as vis from './visibility';

const getPassword = () => {
    const password = document.getElementById('user_password').value;
    const confirm = document.getElementById('user_password_confirmation').value;

    const match = password === confirm;
    const includesDigit = /\d/.test(password);
    const includesUpperCase = /[A-Z]/.test(password);
    const isValidLength = password.length >= 8 && password.length <= 30;

    toggleElement('password-match-alert', match);
    toggleElement('password-case-alert', includesUpperCase);
    toggleElement('password-digit-alert', includesDigit);
    toggleElement('password-length-alert', isValidLength);
}

const getPasswordIfConfirmNotEmpty = () => {
    const confirm = document.getElementById('user_password_confirmation').value;
    if(confirm) getPassword();
}

const toggleElement = (element, hide) => {
    hide ? vis.hideById(element) : vis.showById(element);
}

const validatePassword = () => {
    const conf = document.getElementById('user_password_confirmation');
    // Check Password is valid every time Confirmation field changes
    if(conf) conf.addEventListener('change', getPassword);
    else return;

    const pword = document.getElementById('user_password');
    // Check Password is valid when Password changes IF Confirmation field is 
    // not empty
    if(pword) pword.addEventListener('change', getPasswordIfConfirmNotEmpty);
}

document.addEventListener('turbolinks:load', validatePassword);
