import Rails from "@rails/ujs";

const countyMap = () => {
  const irelandMap = document.querySelector("#irelandmap");
  if (irelandMap) {
    const counties = document.querySelectorAll(".county-anchor");
    for (let i = 0; i < counties.length; i++) {
      const county = counties[i];
      county.addEventListener("click", sendRequest);
    }
  }
};

const sendRequest = (e) => {
  const county = e.target.parentElement;
  const countyID = county.getAttribute("data-county");

  if (countyID) {
    const url = "/home/counties/" + countyID;
    Rails.ajax({
      url: url,
      type: "get",
      data: "",
      success: function (data) {},
      error: function (data) {},
    });
  }
};

document.addEventListener("turbolinks:load", countyMap);
