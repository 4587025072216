document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('[data-scroll-to=true]');
  // for loop over elements and add an event listener on click to each 
  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', (event) => {
      event.preventDefault();
      const target = document.getElementById(event.target.dataset.anchor);
      if(target){
        const header = document.querySelector('header');
        const headerHeight = header.offsetHeight;
        const flash = document.querySelector('.flash');
        let flashHeight = 0;
        if(flash) flashHeight = flash.offsetHeight;
        const offset = headerHeight + flashHeight;
        target.scrollIntoView({ behavior: 'smooth' });
        // const elementYPosition = target.getBoundingClientRect().top;
        // console.log(elementYPosition);
        // window.scrollTo(0, elementYPosition - offset, { behavior: 'smooth' });
      }
    });
  }
})