import getToken from '../app/getToken';

const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

document.addEventListener('turbolinks:load', () => {

  const videoViewBtn = document.getElementById('video-view-button');

  if (videoViewBtn) {
    videoViewBtn.addEventListener('click', (e) => createVideoView(e))
  }
} );


const createVideoView = (e) => {
  const userId = e.target.dataset.userId;
  const lessonId = e.target.dataset.lessonId;

  const url = `/lessons/${lessonId}/video_views` ;

  fetch(url, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({ video_view: {
      user_id: userId,
    } })
  })
    .then(res => res.json())
    .then(res => {
      if(!res.success) console.log(res.error)
      else {
        const viewsCounter = document.getElementById('video-views-count');
        const viewsCounterText = document.getElementById('video-views-count-text');
        if (viewsCounter) {
          viewsCounter.innerText = res.data.count
          viewsCounterText.innerText = `view`
          if(res.data.count !== 1) viewsCounterText.innerText += 's'
        }

      }
    })
    .catch(e => { console.log(e) })
}