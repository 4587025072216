import * as vis from './visibility'


const toggleFieldsPair = () => {
  const elements = document.querySelectorAll('.toggle-fields-pair');
  for(let i = 0; i < elements.length; i++){
    const el = elements[i];
    el.addEventListener('click', () => {
      const fields = el.getAttribute('data-toggle-fields-pair').split(' ')
      vis.showById(fields[0]);
      vis.hideById(fields[1]);
    })
  }
}


document.addEventListener('turbolinks:load', toggleFieldsPair);
