import * as colors from './colors';

const recordCardColors = () => {
  const records = document.querySelectorAll('.record-card')
  if(records){
    let colorSelection = [colors.cardColors].flat()
    while(records.length > colorSelection.length) {
      colorSelection.push(colors.cardColors);
      colorSelection = colorSelection.flat();
     }
    for(let i = 0; i < records.length; i++){
      const record = records[i];

      // TODO refactor
      if(record.classList.contains('no-bg')){
        const colorToAvoid = findColorClass(record.classList);
        if(colorSelection[i] === colors.colorRef[colorToAvoid]){
          record.style.backgroundColor = '#fff'
        } else {
          record.style.backgroundColor = colorSelection[i];
        }
      } else {
        record.style.backgroundColor = colorSelection[i];
      }

    }
  }
}

const findColorClass = (classList) => {
  for(let i = 0; i < classList.length; i++){
    const klass = classList[i];
    if(klass.includes('no-bg-')){
      return klass.slice('no-bg-'.length)
    }
  }
}

// const next = (collection, index) => {
//   if(index > (collection.length - 1)){
//     return collection[index - collection.length]
//   } else {
//     return collection[index]
//   }
// }

document.addEventListener('turbolinks:load', recordCardColors)
