const scrollToParam = () => {
  const url = new URL(window.location.href);
  const scrollTo = url.searchParams.get("scroll_to");
  if (scrollTo)
    setTimeout(() => {
      scroll(scrollTo);
    }, 1000);
};

const scroll = (scrollTo) => {
  const element = document.getElementById(scrollTo);
  if (element) {
    const header = document.querySelector("header");
    const headerHeight = header.offsetHeight;
    const flash = document.querySelector(".flash");
    let flashHeight = 0;
    if (flash) flashHeight = flash.offsetHeight;
    const offset = headerHeight + flashHeight;
    const elementYPosition = element.getBoundingClientRect().top;
    window.scrollTo({
      top: elementYPosition - offset,
      left: 0,
      behavior: "smooth",
    });
  }
};

document.addEventListener("turbolinks:load", scrollToParam);
