// Size community items grid to be height of 3 items
const communityItemsGrid = () => {
  const grids = document.querySelectorAll('.community-items-grid');
  for(let i = 0; i < grids.length; i++){
    const grid = grids[i];
    const card = grid.children[0].children[0];
    grid.style.maxHeight = (card.offsetHeight * 3) + 'px';;
  }
  // Use loaded variable to determine whether to set listener
  // to avoid the listener calling itself again.
  if(!loaded) setResizeListener();
  loaded = true;
}


// resize record height on window resize
const setResizeListener = () => {
  window.addEventListener('resize', () => {
    communityItemsGrid();
  })
}

document.addEventListener('turbolinks:load', () => {
  loaded = false;
  communityItemsGrid()
})
