let loaded;

const sizeRecordHeight = () => {
  const records = document.querySelectorAll('.record-card')
  if(records){
    for(let i = 0; i < records.length; i++){
      const record = records[i];
      record.style.height = record.offsetWidth + 'px';
    }

    // Use loaded variable to determine whether to set listener
    // to avoid the listener calling itself again.
    if(!loaded) setResizeListener();
    loaded = true;
  }
}


// resize record height on window resize
const setResizeListener = () => {
  window.addEventListener('resize', () => {
    sizeRecordHeight();
  })
}



document.addEventListener('turbolinks:load', () => {
  loaded = false;
  sizeRecordHeight()
})
