import Rails from '@rails/ujs';

const submitSearchOnInput = () => {
    const search = document.querySelector('.submit-search-on-input');

    if (search) {
        search.addEventListener('focus', () => {
            document.addEventListener('keyup', submitForm)
        })

        search.addEventListener('blur', () => {
            document.removeEventListener('keyup', submitForm)
        })
    }
}

const submitForm = () => {
    const search = document.querySelector('.submit-search-on-input');

    const forms = document.querySelectorAll('form[data-remote]');
    for (let i = 0; i < forms.length; i++) {
        if (forms[i].contains(search)) {
            Rails.fire(forms[i], 'submit');
        }
    }
}

document.addEventListener('turbolinks:load', submitSearchOnInput)
