var itemTypeSwitcher = function(event) {
  var ds = event.target.dataset;

  if ('switch' in ds) { 
    var itemType = ds.type;

    // we need to disable all fields
    disableFieldsets();

    // and re-enable the ones 
    // in the part of the form
    // that we have switched to
    enableFieldset(itemType);
    updateSwitcher(itemType);
    showSubmitButton();
  }
}

var updateSwitcher = function(itemType) {
  var switcher = document.getElementById('switcher');

  // remove all classes from switcher
  switcher.classList = '';

  // now add the ones we need
  switcher.classList.add('checked');
  switcher.classList.add(itemType);
}

var showSubmitButton = function() {
  var submitButton = document.querySelector('.hidden-when-action-is-new');
  show(submitButton);
}

var disableFieldsets = function() {
  var fieldsets = document.querySelectorAll('fieldset.hidden-fields');

  for (var i=0; i < fieldsets.length; i++) {      
    disableFieldset(fieldsets[i]);
  }
}

var disableFieldset = function(fieldset) {
  fieldset.disabled = true;
}

var enableFieldset = function(itemType) {
  var query = '.' + itemType + '-fields';
  document.querySelector(query).disabled = false;
}

document.addEventListener('click', itemTypeSwitcher, false);
