import * as vis from './visibility'
import * as measure from './measure'

const showItemPopupOnHover = () => {

    const recordCards = document.querySelectorAll('.overlay-card');

    if(recordCards.length > 0){
      recordCards.forEach(card => {
        const id = card.getAttribute('data-id');
        const overlay = document.getElementById(`overlay-${id}`);
        if(overlay){
          card.addEventListener('mouseenter', () => {
              if (!measure.on('small')) {
                  vis.fadeIn(overlay);
              }
          })

          card.addEventListener('mouseleave', () => {
              if (!measure.on('small')) {
                  vis.fadeOut(overlay)
              }
          })
        }
      })
    }

}

document.addEventListener("turbolinks:load", showItemPopupOnHover);
window.addEventListener("resize", showItemPopupOnHover);
